export const Bubbles = () => (
  <div className="container">
    <div className="bubbles-container">
      <svg
        className="bubbles"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 701 1024"
        style={{ overflow: "visible" }}
      >
        <g className="bubbles-large" strokeWidth="7">
          <g>
            <g transform="translate(10 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
          <g>
            <g transform="translate(373 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
          <g>
            <g transform="translate(408 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
          <g>
            <g transform="translate(621 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
          <g>
            <g transform="translate(179 940)">
              <circle cx="35" cy="35" r="35" />
            </g>
          </g>
        </g>

        <g className="bubbles-small" strokeWidth="4">
          <g>
            <g transform="translate(147 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(255 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(573 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(429 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(91 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(640 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(321 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(376 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(376 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
          <g>
            <g transform="translate(497 984)">
              <circle cx="15" cy="15" r="15" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
);
